import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import MailIcon from '@material-ui/icons/Mail';

import SearchEngineOptimization from '../components/SearchEngineOptimization'
import Link from '../components/Link';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '1rem',
  },
  provisionTitle: {
    marginTop: '1rem',
  },
  provision: {
    padding: '0.5rem',
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
  }

}))

const About = ({ data, location }) => {
  const classes = useStyles()
  //const data = props.data
  return (
    <Layout
    siteMetadata={data.allMicrocmsSite.edges[0].node}
      categories={data.allMicrocmsCategory.edges}
      hideCategory={true}>
      <SearchEngineOptimization
        pagetitle="このサイトについて / about this site"
        pagedesc="TKTC の録音及び製作に関する情報を発信しているサイトです。 / this site give you information about TKTC's recordings and creations."
        pagepath={location.pathname}
      />

      <article>
        <Typography className={classes.title} variant="h5" color="inherit" >
          私について / About Me
        </Typography>
        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          私は、TKTC SZK です。作曲と録音、プログラミングを行っています。
          自作のソフトウェアにより、ドラムサンプリング、アナログシンセサイザー、および電子メロトロンを制御してギターと歌の伴奏としています。
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          I am tktc szk. I have been doing composing, recording and programming.
          Using self-made-software, I controll drum samplings analog sythesizers and degital mellotrons as an accompaniment of song and guitar.
        </Typography>

        <Typography className={classes.title} variant="h5" color="inherit" >
          このサイトについて / About This Page
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          本サイトは私が製作した音源、およびライブ等出演情報の発信を目的としています。また、製作に使用している機材や、プログラミングに関することも執筆する予定です。
          なお、好きなレコードや、料理のことなど、エッセーも何か書くかもしれません。
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          This site aims to inform you my recordings and performance dates. I will also write about the instruments or equipments which I use in the recordings, or about programing.
          I might write some essays about my favorite records or cookings too.
        </Typography>

        <hr />

        <Typography className={classes.title} variant="h5" color="inherit" >
          プライバシーポリシー / Privacy Policy
        </Typography>

        <Typography className={classes.provisionTitle} variant="h6" color="inherit" >
          個人情報の収集について / About collection of personal information
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          本サイトは情報発信を目的としており、閲覧者の個人情報を収集しておりません。また、広告配信やアフィリエイト広告についても使用していません。
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          This site aims to give you information. This site does not collect any personal information. This site does not use any advertisement deliveries nor any affiliate advertisements.
        </Typography>

        <Typography className={classes.provisionTitle} component="h3" variant="h6" color="inherit" >
          クッキー情報の利用について / About Usage of cookie data
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          本サイトはアクセス解析のために、クッキー情報を利用することがあります。
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          This site may use cookie data for access analysis.
        </Typography>

        <Typography className={classes.provisionTitle} component="h3" variant="h6" color="inherit" >
          免責事項 / Disclaimer
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          本サイトは、掲載内容によって生じた損害に対する一切の責任を負いません。各コンテンツでは、できる限り正確な情報提供を心がけておりますが、正確性や安全性を保証するものではありません。
          また、リンク先の他サイトで提供される情報・サービスについても、責任を負いかねますのでご了承ください。
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          This site does not take any responsibility for damages caused by the posted contents. We try to provide as accurate information as possible in each content,
          but we do not guarantee its accuracy or safety.
          In addition, please note that we are not responsible for the information and services provided on other linked sites.
        </Typography>

        <Typography className={classes.provisionTitle} component="h3" variant="h6" color="inherit" >
          著作権 / Copyrights
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          本サイトに掲載されている文章、画像、および音源の著作権は、サイト運営者に帰属しています。法的に認められている引用の範囲を超えて、無断で転載することを禁止します。
        </Typography>

        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          The copyright of the text, images, and sound sources posted on this site belongs to the site manager.
          Reproduction without permission is prohibited beyond the scope of legally permitted citations.
        </Typography>

        <Typography className={classes.provisionTitle} component="h3" variant="h6" color="inherit" >
          サイト運営者/Site Manager
        </Typography>
        <Typography className={classes.provision} component="div" variant="body2" color="inherit" >
          <Link className={classes.wrapIcon} to="https://twitter.com/tktc_szk"><TwitterIcon />tktc szk</Link>
          <span>/</span>
          <span className={classes.wrapIcon}><MailIcon />info.tktcszk [at] gmail.com</span>
        </Typography>

      </article>

    </Layout>
  );
}

export const query = graphql`
query {
  allMicrocmsCategory(sort: {fields: categoryNo, order: ASC}) {
    edges {
      node {
        categoryTitle
        categorySlug
        categoryHero {
          url
          height
          width
        }
        categoryNo
      }
    }
  }
  allMicrocmsSite {
    edges {
      node {
        id
        hero {
          url
          height
          width
        }
        title
        desc
        siteUrl
        lang
        locale
      }
    }
  }
}
`
export default About
